<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">API Keys</span>
        </v-card-title>
        <v-card-subtitle>
            Access tokens programmatically authenticate your identity to Nuvolos, allowing applications to perform specific actions.
        </v-card-subtitle>
        <v-card-text>
            <v-skeleton-loader v-if="requestRunning === 'fetchApiKeys'" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else>
                <div class="d-flex align-center">
                    <v-btn @click="openDialog()" class="mr-2" color="primary">
                        <v-icon left>add</v-icon>
                        <span class="font-weight-bold">add new API key</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        label="Search for an API key..."
                        autofocus
                        v-model="search"
                        hide-details
                        prepend-inner-icon="mdi-filter"
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        clearable
                        class="mr-2"></v-text-field>
                    <v-btn :loading="requestRunning === 'fetchApiKeys'" @click="fetchApiKeys()" icon><v-icon>refresh</v-icon></v-btn>
                </div>
                <v-data-table
                    :items="apiKeys"
                    item-key="apid"
                    :headers="headers"
                    :search="search"
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:no-data>
                        <span>No API keys have been added yet. Click add new API key to create a new one.</span>
                    </template>
                    <template v-slot:[`item.apid`]="{ item }">
                        {{ item.apid }}
                    </template>
                    <template v-slot:[`item.type`]="{ item }">
                        <v-chip color="success" small>{{ item.type }}</v-chip>
                    </template>
                    <template v-slot:[`item.valid_from`]="{ item }">
                        {{ item.valid_from | dateTimeToHuman }}
                    </template>
                    <template v-slot:[`item.valid_until`]="{ item }">
                        {{ item.valid_until | dateTimeToHuman }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small color="secondary" @click="openDialog()" class="mr-2" v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-rotate-left</v-icon>
                                </v-btn>
                            </template>
                            <span>Rotate API key</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    color="secondary"
                                    @click="openDeleteDialog(item)"
                                    :loading="requestRunning === 'delete'"
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon small>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete API key</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </template>
        </v-card-text>
        <v-dialog v-model="dialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>Your new API key</v-card-title>
                <v-card-text>
                    <v-alert type="info" text dense class="mt-5 mb-6">
                        <strong>Be advised that you won't be able to view the API key later!</strong>
                    </v-alert>
                    <dashboard-loader v-if="requestRunning === 'newApiKey'">Generating API key</dashboard-loader>
                    <template v-else>
                        <template v-if="!$isError(apiKey)">
                            <code class="text-body-1 py-2 px-4 mr-2">
                                {{ apiKey }}
                            </code>
                            <copy-to-clipboard :textToCopy="apiKey" tooltip="Copy API key to clipboard" :iconButton="true" />
                        </template>
                        <dashboard-error v-if="$isError(apiKey)"></dashboard-error>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog = false" :disabled="requestRunning === 'newApiKey'">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import eventBus from '@/components/EventBus'
const DashboardLoader = () => import('@/components/DashboardLoader.vue')
const DashboardError = () => import('@/components/DashboardError.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    mixins: [enumsData],
    components: { CopyToClipboard, DashboardLoader, DashboardError },
    data() {
        return {
            apiKeys: [],
            apiKey: '',
            search: '',
            dialog: false,
            requestRunning: '',
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            }
        }
    },
    computed: {
        headers() {
            return [
                { text: 'Id', align: 'left', value: 'apid' },
                { text: 'Type', align: 'left', value: 'type' },
                { text: 'Last rotation', align: 'left', value: 'valid_from' },
                { text: 'Actions', align: 'right', value: 'actions' }
            ]
        }
    },
    methods: {
        openDialog() {
            this.dialog = true
            this.addApiKey()
        },
        openDeleteDialog() {
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `Are you sure you would like to delete the API key?`,
                dialogAction: ['delete', 'cancel'],
                dialogTitle: 'Confirm deletion'
            })
        },
        async addApiKey() {
            this.requestRunning = 'newApiKey'
            try {
                const { data } = await this.$axios.post(`/authentication/api_key`)
                this.apiKey = data
                this.fetchApiKeys()
            } catch (error) {
                this.apiKey = error
            }
            this.requestRunning = ''
        },
        async fetchApiKeys() {
            this.requestRunning = 'fetchApiKeys'
            this.apiKeys = []
            try {
                const { data } = await this.$axios.get(`/authentication/api_key`)
                data.forEach(apiKey => {
                    apiKey.type = 'auto mount'
                })
                this.apiKeys = data
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Error fetching API keys',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.requestRunning = ''
        },
        async deleteApiKey() {
            try {
                await this.$axios.delete(`/authentication/api_key/${this.apiKeys[0].apid}`)
                this.fetchApiKeys()
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Error deleting API key',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        }
    },
    created() {
        this.fetchApiKeys()
        eventBus.$on('global-dialog-accept', async () => {
            this.deleteApiKey()
        })
    }
}
</script>
